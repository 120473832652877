import React from "react";
import authctx from "../contexts/authctx.js";
import withRouter from "../components/wrappers/withrouter.js";
import { enqueueSnackbar } from "notistack";
import { Card, Spinner, Alert, Button, Accordion, Avatar, Popover } from "flowbite-react";
import { acceptSession, cancelParticipation, cancelSession, getSession } from "../services/api.js";
import FutSession from "../components/FutSession.js";
import { TbCrown, TbPlayFootball } from "react-icons/tb";
import { getTerrainPlacesByNumber, hasPlayerAnsweredToGame, hasPlayerRefusedGame, isGameFull, isGamePassed, isPlayerRegisteredToGame, isPlayerSubstituteToGame, isUserOrganizer } from "../services/futsession.js";
import { DASHBOARD_ROUTE, USER_DETAILS } from "../services/routes.js";
import { getRouteWithReplacement } from "../services/router.js";
import unknownAvatar from "../images/unknown.svg";
import { NavLink } from "react-router-dom";

class FutSessionDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {data: null, error: false};
  }

  componentDidMount = () => {
    this.handleGetData();
  }

  handleGetData = async () => {
    try {
      const resp = await getSession(this.props.router.params.id, true);

      this.setState({data: resp.session, teams: resp.teams});
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des données",
        {variant: "error"},
      );
      this.props.router.navigate(
        getRouteWithReplacement(DASHBOARD_ROUTE),
      );
    }
  }

  handleRegisterToGame = async () => {
    try {
      await acceptSession(this.state.data.id, true);

      enqueueSnackbar(
        "Vous avez bien été inscrit à la partie",
        {variant: "success"}
      );

      this.setState({ data: null });
      this.handleGetData();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de l'inscription à la partie",
        { variant: "error" },
      )
    }
  };

  handleRefuseGame = async () => {
    try {
      await acceptSession(this.state.data.id, false);

      enqueueSnackbar(
        "La partie a bien été annulée",
        { variant: "success" },
      );

      this.setState({data: null});
      this.handleGetData();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors du refus de la partie",
        { variant: "error" },
      )
    }
  };

  handleCancelGame = async () => {
    try {
      await cancelSession(this.state.data.id);

      enqueueSnackbar(
        "La partie a bien été annulée",
        {variant: "success"},
      );

      this.props.router.navigate(DASHBOARD_ROUTE);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de l'annulation de la partie",
        {variant: "error"},
      )
    }
  }

  handleCancelParticipation = async () => {
    try {
      await cancelParticipation(this.state.data.id);

      enqueueSnackbar(
        "Votre participation a bien été annulée",
        { variant: "success" },
      );

      this.handleGetData();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de l'annulation de votre participation",
        { variant: "error" },
      )
    }
  }

  _renderPlayer = (player) => {
    return <NavLink to={getRouteWithReplacement(USER_DETAILS, {id: player.id})} key={player.id}>
      <li className="py-3 sm:py-4">
        <div className="flex items-center space-x-4">
          <div className="shrink-0">
            <Avatar 
              alt="User settings"
              img={(props) => <img
                src={player.avatar || unknownAvatar}
                style={{objectFit: "contain"}}
                alt=""
                {...props}
              />}
              rounded
              bordered
            />
          </div>
          <div className="min-w-0 flex-1">
            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
              {player.firstName} {player.lastName} {player.nickname ? `(${player.nickname})` : ""}
            </p>
            {player.averageNote !== 0 ? <small>
              <span className="font-bold mr-1">{player.averageNote}</span>
              ({player.noteSpeed} PAC - {player.noteShoot} SHO - {player.notePass} PAS - {player.noteDribble} DRI - {player.noteDefense} DEF - {player.notePhysical} PHY)
            </small> : null}
            <p>
              <small>
                <TbPlayFootball className="inline mr-1" size={15} /> {player.preferedPosition}
              </small>
            </p>
          </div>
          {player.id === this.state.data.organiser.id ? <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            <TbCrown size={25} />
          </div> : null}
        </div>
      </li>
    </NavLink>;
  }

  _renderValidatedList = () => {
    const session = this.state.data;
    const players = session.validatedPlayers;

    return <Card>
      <div className="mb-4 flex items-center justify-between">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          Joueurs ({players.length})
        </h5>
      </div>
      <div className="flow-root">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">

          {players.length === 0 ? <div className="text-center">
            Aucun joueur n'est inscrit à cette partie
          </div>
          : players.map(this._renderPlayer)}
        </ul>
      </div>
    </Card>
  }

  _renderSubstitutesList = () => {
    const session = this.state.data;
    const substitudesCount = session.substitutePlayers.length;

    if (substitudesCount === 0) return null;

    const substitutes = session.substitutePlayers;

    return <Card>
      <div className="mb-4 flex items-center justify-between">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Remplaçants</h5>
      </div>
      <div className="flow-root">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          {substitutes.map(this._renderPlayer)}
        </ul>
      </div>
    </Card>
  }

  _renderRefusedList = () => {
    const session = this.state.data;

    const refusedPlayers = session.refusedPlayers;
    if (refusedPlayers.length === 0) return null;

    return <Accordion collapseAll className="w-full">
      <Accordion.Panel>
        <Accordion.Title>Joueurs ayant déclinés ({refusedPlayers.length})</Accordion.Title>
        <Accordion.Content>
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {refusedPlayers.map(this._renderPlayer)}
            </ul>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>;
  }

  _renderFullGameAlert = () => {
    if (this.state.data.validatedPlayers.length === this.state.data.maxPlayerCount 
      && !hasPlayerAnsweredToGame(this.props.authCtx.profile.id, this.state.data)) {
      return <Alert color="warning">
        La partie est complète, mais vous pouvez toujours vous inscrire en tant que remplaçant. 
        Vous recevrez un mail si une place se libère et vous y serez assigné automatiquement.
        Les places sont données dans l'ordre d'inscription des remplaçants.
      </Alert>;
    }
  };

  _renderActualUserAlert = () => {
    if (this.props.hideAlert) return null;

    if (isGamePassed(this.state.data)) {
      if (isPlayerRegisteredToGame(this.props.authCtx.profile.id, this.state.data)) {
        return <Alert color="success" rounded>
          La partie est passée, merci d'avoir participé!
        </Alert>;
      }

      return <Alert color="failure" rounded>
        La partie est passée
      </Alert>;
    }

    if (hasPlayerRefusedGame(this.props.authCtx.profile.id, this.state.data)) {
      return <Alert color="failure" rounded>
        Vous avez refusé de participer à cette partie
      </Alert>;
    }
    if (isPlayerSubstituteToGame(this.props.authCtx.profile.id, this.state.data)) {
      return <Alert color="warning" rounded>
        Vous êtes inscrit comme remplaçant potentiel à cette partie
      </Alert>;
    }
    if (isPlayerRegisteredToGame(this.props.authCtx.profile.id, this.state.data)) {
      return <Alert color="success" rounded>
        Vous êtes inscrit à cette partie
      </Alert>;
    }
  }

  _renderAcceptButtons = () => {
    const userId = this.props.authCtx.profile.id;
    if (hasPlayerAnsweredToGame(userId, this.state.data)) {

      if (isPlayerRegisteredToGame(userId, this.state.data) && !isGamePassed(this.state.data)) {
        return <Button color="failure" className="w-full" onClick={this.handleCancelParticipation}>
          Annuler sa participation
        </Button>;
      }

      if (isPlayerSubstituteToGame(userId, this.state.data)) {
        return <Button color="failure" className="w-full" onClick={this.handleCancelParticipation}>
          Annuler sa disponibilité
        </Button>;
      }

      if (hasPlayerRefusedGame(userId, this.state.data) && !isGameFull(this.state.data)) {
        return <Button color="warning" className="w-full" onClick={this.handleRegisterToGame}>
          Participer
        </Button>;
      }

      if (hasPlayerRefusedGame(userId, this.state.data) && isGameFull(this.state.data)) {
        return <Button color="failure" className="w-full" onClick={this.handleRegisterToGame}>
          S'inscrire comme remplaçant
        </Button>;
      }

      return null;
    }

    return <div className="flex space-x-3">
      <Button color="success" className="w-full" onClick={this.handleRegisterToGame}>
        S'inscrire
      </Button>
      <Button color="failure" className="w-full" onClick={this.handleRefuseGame}>
        Refuser
      </Button>
    </div>;
  }

  _renderCancelGameButton = () => {
    if (
      !isUserOrganizer(this.props.authCtx.profile.id, this.state.data)
      || isGamePassed(this.state.data)
      ) return null;

    // TODO: Button is disabled until the cancel function is fixed
    return <>
      <hr />

      <Button color="failure" className="w-full" onClick={this.handleCancelGame} disabled>
        Annuler la partie
      </Button>
    </>;
  }

  _renderMapPlayer = (player, index, team) => {
    const places = getTerrainPlacesByNumber(this.state.data.maxPlayerCount);

    return <Popover
      placement="top"
      content={
        <div className="w-64 p-3">
          <div className="mb-2 flex items-center justify-between">
              <img
                className="h-10 w-10 rounded-full"
                src={player.avatar || unknownAvatar}
                alt=""
              />
            <div>
              {player.firstName !== "???" ? <NavLink to={getRouteWithReplacement(USER_DETAILS, {id: player.id})}>
              <button
                type="button"
                className="rounded-lg bg-blue-700 px-3 py-1.5 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Profil
              </button>
              </NavLink> : null}
            </div>
          </div>
          <p id="profile-popover" className="text-base font-semibold leading-none text-gray-900 dark:text-white">
            {player.firstName} {player.lastName}
          </p>

          {player.nickname && player.nickname !== "" ? <p className="mb-3 text-sm font-normal">
            {player.nickname}
          </p> : null}

          {player.averageNote !== 0 && !player.hasGeneratedNotes ? <small>
            <span className="font-bold mr-1">{player.averageNote}</span>
            ({player.noteSpeed} PAC - {player.noteShoot} SHO - {player.notePass} PAS - {player.noteDribble} DRI - {player.noteDefense} DEF - {player.notePhysical} PHY)
          </small> : null}
        </div>
      }
    >
      <div className={places[team][index]}>
        <Avatar
          alt="User settings"
          img={(props) => <img
            src={player.avatar || unknownAvatar}
            style={{
              objectFit: "cover",
              backgroundColor: "white",
            }}
            alt=""
            className="xs:h-30 xs:w-30"
            {...props}
          />}
          style={{
            height: 40,
            width: 50,
            transform: "translateX(-18px) translateY(-16px)"
            }}
            className="xs:h-20 xs:w-20 h-[40] w-[50]"
          rounded
        />
        <div className="text-white text-xs " style={{ transform: "translateY(-18px)"}}>{player.averageNote}</div>
      </div>
    </Popover>
  }

  _renderBestTeamRepartition = () => {
    if (this.state.teams) {
      return <Accordion collapseAll>
        <Accordion.Panel>
          <Accordion.Title>Équipes les plus équilibrées</Accordion.Title>
          <Accordion.Content>

            <div className="wrapper">
              <div className="campo">
                <div className="semi1" />
                <div className="semi2" />
                <div className="divisoria" />
                <div className="interior" />
                <div className="penalty" />

                {this.state.teams.a.map((player, index) => this._renderMapPlayer(player, index, "a"))}
                {this.state.teams.b.map((player, index) => this._renderMapPlayer(player, index, "b"))}
              </div>
            </div>

          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>;
    }
  }

  render() {
    if (!this.state.data) {
      return <div className="flex justify-center items-center">
        <Spinner />
      </div>;
    }

    return <>
      <h6 className="text-3xl text-center pt-3 pb-5">SESSION {this.props.router.params.id}</h6>
      <hr />
      {this._renderActualUserAlert()}
      {this._renderFullGameAlert()}
      {this._renderAcceptButtons()}

      <FutSession
        session={this.state.data}
        hideLink
        details
        hideAlert
      />

      {this._renderBestTeamRepartition()}
      {this._renderValidatedList()}
      {this._renderSubstitutesList()}
      {this._renderRefusedList()}
      {this._renderCancelGameButton()}
    </>;
  }
}

export default withRouter(authctx.withCtx(FutSessionDetails));
