export const HOME_ROUTE = "/"; 

export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/signup";
export const FORGOT_PASSWORD_ROUTE = "/forgotPassword";
export const QUICK_ACCEPT_ROUTE = `/quick-response`;

export const DASHBOARD_ROUTE = "/app";
export const USER_DETAILS = `${DASHBOARD_ROUTE}/user/:id`;
export const USER_SETTINGS_ROUTE = `${DASHBOARD_ROUTE}/usersettings`;

export const SESSIONS_ROUTE = `${DASHBOARD_ROUTE}/sessions`;
export const CREATE_SESSIONS_ROUTE = `${DASHBOARD_ROUTE}/session/create`;
export const SESSION_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/session/details/:id`;
export const SESSION_LIST_ROUTE = `${DASHBOARD_ROUTE}/session/list`;
