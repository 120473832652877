import { Avatar, Button, FileInput, Label, Select, Spinner, TextInput } from "flowbite-react";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { getProfile, updateProfile, uploadAvatar } from "../services/api.js";
import imglyRemoveBackground from "@imgly/background-removal";
import imageCompression from "browser-image-compression";
import authctx from "../contexts/authctx.js";
import heic2any from "heic2any";
import { b64toBlob, mobileCheck } from "../services/images.js";
import unknownAvatar from "../images/unknown.svg"

class UserSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    this.handleGetData();
  }

  handleChange = (ev) => this.setState({[ev.target.name]: ev.target.value});
  handleDateChange = (selectedDate) => this.setState({birthdate: selectedDate});

  handleGetData = async () => {
    try {
      const profile = (await getProfile()).data.user;

      this.setState({
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        nickname: profile.nickname,
        avatar: profile.avatar,
        preferedPosition: profile.preferedPosition,
        loading: false,
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        "Erreur avec la récupération de vos données",
        {variant: "error"},
      );
    }
  }

  handleSubmit = async () => {
    try {
      const req = {};

      if (this.state.email && this.state.email !== "") req.email = this.state.email;
      if (this.state.firstName && this.state.firstName !== "") req.firstName = this.state.firstName;
      if (this.state.lastName && this.state.lastName !== "") req.lastName = this.state.lastName;

      if (this.state.nickname && this.state.nickname !== "") {
        req.nickname = this.state.nickname;
      } else {
        req.nickname = "";
      }

      await updateProfile({
        ...req,
        preferedPosition: this.state.preferedPosition,
      });

      this.props.authCtx.getProfile();

      enqueueSnackbar(
        "Vos données ont été mises à jour",
        {variant: "success"},
      );
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        "Erreur avec la mise à jour de vos données",
        {variant: "error"},
      );
    }
  }

  handleFileUpload = async (ev) => {
    try {
      let file = ev.target.files[0];
      
      this.setState({
        loadingAvatar: true,
        loadingAvatarMessage: "Compression de l'image...",
      });

      const lowerCaseFileName = file.name.toLowerCase();
      if (lowerCaseFileName.endsWith(".heic")) {
        this.setState({loadingAvatarMessage: "Conversion de l'image..."});

        file = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
      }

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        preserveExif: false,
        maxWidthOrHeight: 500,
      });

      const reader = new FileReader();

      reader.onload = async (ev) => {
        const base64 = ev.target.result;
        const defaultAvatar = this.state.avatar;

        this.setState({
          avatar: base64,
          loadingAvatarMessage: "Suppression du fond...",
        });

        try {
          let img;
          if (mobileCheck()) {
            enqueueSnackbar(
              "Le fond n'a pas été supprimé car vous utilisez un appareil mobile.",
              {variant: "warning"},
            );
          } else {
            img = await imglyRemoveBackground(base64, {model: "small"});
          }

          if (!img) img = b64toBlob(base64);

          const url = URL.createObjectURL(img);

          this.setState({
            avatar: url,
            loadingAvatarMessage: "Envoi de l'avatar...",
          });

          const imgToUpload = new File([img], "avatar.png", {type: "image/png"});
    
          await uploadAvatar(imgToUpload);
    
          this.setState({
            avatar: url,
            loadingAvatar: false,
          });
    
          await this.props.authCtx.getProfile();
    
          enqueueSnackbar(
            "Avatar mis à jour",
            {variant: "success"},
          );

        } catch (err) {
          console.error(err);
          enqueueSnackbar(
            "Erreur avec la mise à jour de l'avatar",
            {variant: "error"},
          );

          this.setState({
            avatar: defaultAvatar,
            loadingAvatar: false,
            avatarError: err.message,
          });
        }
      }

      this.setState({loadingAvatarMessage: "Chargement de l'avatar..."});

      reader.readAsDataURL(compressedFile);
    } catch (err) {
      this.setState({loadingAvatar: false});
      enqueueSnackbar(
        "Erreur avec la mise à jour de l'avatar",
        {variant: "error"},
      )
    }
  }

  _renderAvatarUpload = () => {
    if (this.state.loadingAvatar) {
      return <div className="text-center">
        <Spinner />
        <p>{this.state.loadingAvatarMessage}</p>
      </div>;
    }

    return <div id="fileUpload" className="max-w-md">
      <div className="mb-2 block">
        <Label htmlFor="file" value="Avatar" />
      </div>

      <FileInput
        id="file"
        onChange={this.handleFileUpload}
        helperText="Le fond sera automatiquement retiré si vous n'êtes pas sur mobile"
        name="filenametest"
      />
    </div>;
  }

  _renderPreferedPositionInput = () => {
    return <div>
      <div className="mb-2 block">
        <Label htmlFor="preferedPosition" value="Position préférée sur le terrain" />
      </div>
      <Select
        id="preferedPosition"
        name="preferedPosition"
        required value={this.state.preferedPosition}
        onChange={this.handleChange}
      >
        <option value="PARTOUT">PARTOUT</option>
        <option value="ATTAQUANT">ATTAQUANT</option>
        <option value="MILIEU">MILIEU</option>
        <option value="DEFENSE">DEFENSE</option>
        <option value="GOAL">GOAL</option>
      </Select>
    </div>;
  }

  render = () => {
    if (this.state.loading) return <div className="text-center">
      <Spinner />
    </div>;

    return <>
      <h6 className="text-3xl text-center pt-3 pb-5">
        Paramètres du compte
      </h6>

      <Avatar
        img={(props) => <img 
          src={this.state.avatar ?? unknownAvatar}
          {...props}
          style={{objectFit: "contain"}}
          alt=""
        />}
        rounded
        size="xl"
        theme={{root: {objectFit: "cover"}}}
        style={{objectFit: "cover"}}
        bordered
      />

      {this.state.avatarError ? JSON.stringify(this.state.avatarError) : null}

      {this._renderAvatarUpload()}

      <div>
        <div className="mb-2 block">
          <Label htmlFor="email" value="E-mail" />
        </div>
        <TextInput 
          id="email"
          type="email"
          name="email"
          value={this.state.email}
          onChange={this.handleChange}
          disabled
          required
        />
      </div>

      <div>
        <div className="mb-2 block">
          <Label htmlFor="firstName" value="Prénom" />
        </div>
        <TextInput 
          id="firstName"
          type="firstName"
          name="firstName"
          value={this.state.firstName}
          onChange={this.handleChange}
          required
        />
      </div>

      <div>
        <div className="mb-2 block">
          <Label htmlFor="lastName" value="Nom de famille" />
        </div>
        <TextInput 
          id="lastName"
          type="lastName"
          name="lastName"
          value={this.state.lastName}
          onChange={this.handleChange}
          required
        />
      </div>

      <div>
        <div className="mb-2 block">
          <Label htmlFor="nickname" value="Surnom" />
        </div>
        <TextInput 
          id="nickname"
          type="nickname"
          name="nickname"
          value={this.state.nickname}
          onChange={this.handleChange}
          maxLength={16}
          required
        />
      </div>

      {this._renderPreferedPositionInput()}

      <Button
        onClick={this.handleSubmit}
        disabled={this.state.email === ""
          || this.state.firstName === ""
          || this.state.lastName === ""
          || this.state.loadingAvatar}
      >
        Sauvegarder
      </Button>

    </>;
  }
}

export default authctx.withCtx(UserSettings);
