'use client'; 

import React from "react";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UserSettings from "./pages/UserSettings";
import {
  CREATE_SESSIONS_ROUTE,
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  QUICK_ACCEPT_ROUTE,
  SESSION_DETAILS_ROUTE,
  SESSION_LIST_ROUTE,
  SIGNUP_ROUTE,
  USER_DETAILS,
  USER_SETTINGS_ROUTE
} from "./services/routes.js";
import AuthCtx from "./contexts/authctx.js";
import Basepage from "./components/layout/basepage.js";
import { SnackbarProvider } from "notistack";
import ForgotPassword from "./pages/ForgotPassword.js";
import Footer from "./components/Footer.js";
import {Flowbite} from "flowbite-react";
import CreateSession from "./pages/CreateSession.js";
import FutSessionDetails from "./pages/FutSessionDetails.js";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';
import SessionList from "./pages/SessionsList.js";
import QuickAcceptSession from "./pages/QuickAcceptSession.js";
import UserDetails from "./pages/UserDetails.js";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale('fr');

const router = createBrowserRouter([
  {
    path: HOME_ROUTE,
    element: <Home />,
  },
  {
    path: LOGIN_ROUTE,
    element: <Login />,
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    element: <ForgotPassword />,
  },
  {
    path: SIGNUP_ROUTE,
    element: <SignUp />,
  },
  {
    path: QUICK_ACCEPT_ROUTE,
    element: <QuickAcceptSession />,
  },
  {
    path: DASHBOARD_ROUTE,
    element: <Basepage />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: CREATE_SESSIONS_ROUTE,
        element: <CreateSession />,
      },
      {
        path: SESSION_LIST_ROUTE,
        element: <SessionList />,
      },
      {
        path: SESSION_DETAILS_ROUTE,
        element: <FutSessionDetails />,
      },
      {
        path: USER_DETAILS,
        element: <UserDetails />,
      },
      {
        path: USER_SETTINGS_ROUTE,
        element: <UserSettings />,
      },
    ]
  },
]);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {loading: true};

    AuthCtx.init(this);
  }

  componentDidMount = async () => {
    try {
      await this.state.authCtx.getProfile();

      // if (profile) {
        // router.navigate(DASHBOARD_ROUTE);
      // }
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render = () => {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <Flowbite>
        <AuthCtx.Provider stateRef={this}>
          <main className="dark:bg-zinc-900 dark:text-white min-h-screen overflow-x-hidden">
            <SnackbarProvider />
            <RouterProvider router={router} />
            <Footer />
          </main>
        </AuthCtx.Provider>
      </Flowbite>
    );
  }
}

export default App;
