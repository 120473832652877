import { DarkThemeToggle } from "flowbite-react";
import React from "react";
import packageJson from "../../package.json";

const Footer = () => {
  return (
    <div className="h-16 border-t flex justify-center text-xs items-center ">
      Made by COURTABRO' - v{packageJson.version} - <DarkThemeToggle />
    </div>
  );
};

export default Footer;
