import React from "react";
import FifaCard from "../components/FifaCard";
import authctx from "../contexts/authctx.js";
import FutSession from "../components/FutSession.js";
import { Accordion, Button, Timeline } from 'flowbite-react';
import { enqueueSnackbar } from "notistack";
import { getLastSessions } from "../services/api.js";
import { TbPlus } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { CREATE_SESSIONS_ROUTE } from "../services/routes.js";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null, nextSession: null};
  }

  componentDidMount = () => {
    this.handleGetData();
  }

  handleGetData = async () => {
    try {
      const nextSession = await getLastSessions(1, 10, { onlyInscriptibles: true});

      this.setState({nextSessions: nextSession.sessions ?? null});
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des données",
        { variant: "error" },
      )
    }
  }

  _renderFifaCardAccordion = () => {
    return <Accordion collapseAll className="w-full">
      <Accordion.Panel>
        <Accordion.Title>Ma carte de joueur</Accordion.Title>
        <Accordion.Content className="text-center">
          <div className="flex justify-center">
            <FifaCard scaleValue={0.4} user={this.props.authCtx.profile} />
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>;
  }

  _renderCreateSessionButton = () => {
    return <Button as={NavLink} to={CREATE_SESSIONS_ROUTE} className="w-full">
      Créer une session
      <TbPlus className="ml-3" />
    </Button>
  };

  _renderTimeline = () =>  {
    const sessions = this.state.nextSessions;
    if (!sessions || sessions.length <= 1) {
      return <p>Aucune session à suivre</p>
    }

    const nextSessions = sessions.slice(1);

    return <Timeline>
      {nextSessions.map((session) => <FutSession
        session={session}
        timeline
        key={session.id}
      />)}
    </Timeline>
  }

  _renderNextSession = () => {
    if (!this.state.nextSessions || this.state.nextSessions.length === 0) {
      return <p>Aucune session à venir</p>
    }

    return <FutSession 
      session={this.state.nextSessions[0]}
      hideDetails
    />
  }

  render() {
    return <>
      <h6 className="text-3xl text-center pt-3 pb-5">Bonjour, {this.props.authCtx.profile.firstName}</h6>
      {this._renderCreateSessionButton()}

      {this._renderFifaCardAccordion()}

      <h6 className="text-xl">Prochaine session disponible</h6>

      {this._renderNextSession()}

      <h6 className="text-xl">Sessions à suivre</h6>
      {this._renderTimeline()}
    </>;
  }
}

export default authctx.withCtx(Dashboard);
