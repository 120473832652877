import dayjs from "dayjs";

export const getStatusBarColorFromPlayers = (progress) => {
  if (progress < 25) return "red";
  if (progress < 50) return "orange";
  if (progress < 75) return "yellow";

  return "lime";
}

export const hasPlayerAnsweredToGame = (userId, session) => {
  return session.respondedPlayers.some(player => player.id === userId);
}

export const isPlayerRegisteredToGame = (userId, session) => {
  return session.validatedPlayers.some(player => player.id === userId);
}

export const isPlayerSubstituteToGame = (userId, session) => {
  return session.substitutePlayers.some(player => player.id === userId);
}

export const hasPlayerRefusedGame = (userId, session) => {
  return session.refusedPlayers.some(player => player.id === userId);
}

export const isUserOrganizer = (userId, session) => {
  return session.organiser.id === userId;
}

export const isGamePassed = (session) => {
  return dayjs(session.date).isBefore(dayjs());
}

export const isGameFull = (session) => {
  return session.validatedPlayers.length === session.maxPlayerCount;
}

export const getTerrainPlacesByNumber = (maxPlayerCount) => {
  switch (maxPlayerCount) {
    case 12:
      return {
        a: ["lb", "lwb", "dm", "rwb", "rb", "cb"],
        b: ["wl", "amr", "am", "aml", "wr", "cf"],
      };
    case 14:
      return {
        a: ["lb", "lwb", "dm", "rwb", "rb", "cb", "gk"],
        b: ["wl", "amr", "am", "aml", "wr", "cf", "st"],
      };
    default:
      return {
        a: ["lb", "lwb", "dm", "rwb", "rb"],
        b: ["wl", "amr", "am", "aml", "wr"],
      };
  }
}