import { enqueueSnackbar } from "notistack";
import config from "../config.js";
import axios from "axios";

const sdk = axios.create({
  baseURL: `${config.apiBaseUrl}/api/v1/`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const hashPassword = (string) => {
  const utf8 = new TextEncoder().encode(string);

  if (!window.crypto.subtle) {
    enqueueSnackbar(
      "Votre navigateur ne supporte pas le chiffrement de mot de passe",
      { variant: "error" },
    );
  }

  return window.crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
}

export const login = async (email, password) => {
  return sdk.post("login", { email, password: await hashPassword(password) });
}

export const signup = async (
  email, 
  password, 
  firstName, 
  lastName, 
  birthdate
) => {
  return sdk.post("register", {
    email,
    password: await hashPassword(password),
    firstName,
    lastName,
    birthdate,
  });
}

export const logout = () => {
  return sdk.post("logout");
}

export const getProfile = () => {
  return sdk.post("profile");
}

export const forgotPassword = (email) => {
  return sdk.post("forgotPassword", { email });
}

export const changeForgottenPassword = async (newPassword, token) => {
  return sdk.post("changeForgottenPassword", { newPassword: await hashPassword(newPassword), token });
}

export const createSession = async (
  placeId,
  date,
  duration,
  maxPlayerCount,
  type,
  userIsInGame,
  pricePerPerson,
) => {
  return (await sdk.post(
    "session/create",
    {
      placeId,
      date,
      duration,
      maxPlayerCount,
      type,
      userIsInGame,
      pricePerPerson
    },
  )).data.session;
};

export const getSession = async (id, withTeams = false) => {
  return (await sdk.post("session/get", { sessionId: id, withTeamMap: withTeams })).data;
}

export const getLastSessions = async (
  page,
  limit,
  options = {},
) => {
  const apiResp = (await sdk.post(
    "session/get/paginated",
    { page, limit, ...options }
  )).data;

  return {sessions: apiResp.sessions, total: apiResp.total};
}

export const cancelSession = (sessionId) => {
  return sdk.post("session/delete", {sessionId});
}

export const acceptSession = (sessionId, accept) => {
  return sdk.post("session/accept", {sessionId, accept});
};

export const quickResponse = async (quickAcceptJwt) => {
  try {
    const resp = await sdk.post("session/accept/quick", {quickAcceptJwt});

    return resp.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateProfile = async (fieldsToUpdate) => {
  return sdk.post("editSelf", fieldsToUpdate);
}

export const uploadAvatar = async (avatar) => {
  const formData = new FormData();
  formData.append("avatar", avatar);

  return sdk.post("uploadAvatar", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export const getUser = async (id) => {
  return (await sdk.post("user/get", {id})).data;
}
 
export const cancelParticipation = async (sessionId) => {
  return (await sdk.post("session/cancelParticipation", { sessionId })).data;
}

