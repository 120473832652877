import React from "react";
import logo from "../images/logo.svg";
import { NavLink } from "react-router-dom";
import {
  CREATE_SESSIONS_ROUTE,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  SESSION_LIST_ROUTE,
  SIGNUP_ROUTE,
  USER_DETAILS,
  USER_SETTINGS_ROUTE,
} from "../services/routes.js";
import authctx from "../contexts/authctx.js";
import {
  Badge,
  Avatar,
  Dropdown,
  Navbar as FlowbiteNavbar,
} from "flowbite-react";
import { TbCrown, TbHome } from "react-icons/tb";
import { HiCog, HiUser } from "react-icons/hi";
import config from "../config.js";
import unknownAvatar from "../images/unknown.svg";
import { getRouteWithReplacement } from "../services/router.js";

class Navbar extends React.Component {
  handleCollapse = () => {
    const navbarToggleButton = document.getElementById("navbarToggleButton");
    if (navbarToggleButton) {
      navbarToggleButton.click();
    }
  };

  _renderLoggedLinks = () => {
    return (
      <>
        <NavLink to={DASHBOARD_ROUTE}>Accueil</NavLink>
        <NavLink to={DASHBOARD_ROUTE}>Sessions</NavLink>
        <span onClick={this.props.authCtx.logout}>Déconnexion</span>
      </>
    );
  };

  _renderLoggedMenu = () => {
    return (
      <>
        <FlowbiteNavbar.Link to={HOME_ROUTE} as={NavLink}>
          <TbHome size={20} />
        </FlowbiteNavbar.Link>
        <FlowbiteNavbar.Link to={SESSION_LIST_ROUTE} as={NavLink}>
          Sessions
        </FlowbiteNavbar.Link>
        <FlowbiteNavbar.Link to={CREATE_SESSIONS_ROUTE} as={NavLink}>
          Créer une session
        </FlowbiteNavbar.Link>
      </>
    );
  };

  _renderGuestMenu = () => {
    return (
      <>
        <FlowbiteNavbar.Link to={HOME_ROUTE} as={NavLink}>
          <TbHome size={20} />
        </FlowbiteNavbar.Link>
        <FlowbiteNavbar.Link to={LOGIN_ROUTE} as={NavLink}>
          Connexion
        </FlowbiteNavbar.Link>
        <FlowbiteNavbar.Link to={SIGNUP_ROUTE} as={NavLink}>
          Inscription
        </FlowbiteNavbar.Link>
      </>
    );
  };

  _renderMenu = () => {
    if (this.props.authCtx.profile) {
      return this._renderLoggedMenu();
    }

    return this._renderGuestMenu();
  };

  _renderAvatar = () => {
    const user = this.props.authCtx.profile;

    if (!user) return null;

    return (
      <div className="flex sm:translate-x-1/2 z-50">
        <Dropdown
          arrowIcon
          inline
          label={
            <Avatar
              alt="User settings"
              img={(props) => (
                <img
                  src={user.avatar ?? unknownAvatar}
                  {...props}
                  style={{ objectFit: "contain" }}
                  alt=""
                />
              )}
              rounded
              bordered
              status="online"
              statusPosition="bottom-right"
            />
          }
          placement="bottom"

        >
          <Dropdown.Header>
            <span className="block text-sm">
              {user.firstName} {user.lastName}{" "}
              {user.isAdmin ? (
                <TbCrown color="green" className="inline mb-1" />
              ) : null}
            </span>
            <span className="block truncate text-sm font-medium">
              {user.email}
            </span>
          </Dropdown.Header>
          <Dropdown.Item
            icon={HiUser}
            as={NavLink}
            to={getRouteWithReplacement(USER_DETAILS, {id: this.props.authCtx.profile.id})}
          >
            Profil
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item icon={HiCog} as={NavLink} to={USER_SETTINGS_ROUTE}>
            Paramètres
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={this.props.authCtx.logout}>
            Déconnexion
          </Dropdown.Item>
        </Dropdown>
      </div>
    );
  };

  render = () => {
    return (
      <FlowbiteNavbar className="p-4 dark:bg-zinc-900 dark:text-white">
        <FlowbiteNavbar.Brand
          as={NavLink}
          to={this.props.authCtx.profile ? DASHBOARD_ROUTE : HOME_ROUTE}
        >
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="FutMates Logo" />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            FutMates
          </span>
          {config.deploymentType === "BETA" ? (
            <Badge className="ml-2" color="warning">
              BETA
            </Badge>
          ) : null}
          {config.deploymentType === "LOCAL" ? (
            <Badge className="ml-2" color="pink">
              LOCAL
            </Badge>
          ) : null}
        </FlowbiteNavbar.Brand>
        {this._renderAvatar()}
        <FlowbiteNavbar.Toggle id="navbarToggleButton" />
        <FlowbiteNavbar.Collapse onClick={this.handleCollapse}>
          {this._renderMenu()}
        </FlowbiteNavbar.Collapse>
      </FlowbiteNavbar>
    );
  };
}

export default authctx.withCtx(Navbar);
