import React from "react";
import NavBar from "../components/NavBar.js";
import { NavLink } from "react-router-dom";
import { DASHBOARD_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from "../services/routes.js";
import authctx from "../contexts/authctx.js";
import withRouter from "../components/wrappers/withrouter.js";

class Home extends React.Component {
  componentDidMount = () => {
    if (this.props.authCtx.profile) {
      return setTimeout(() =>this.props.router.navigate(DASHBOARD_ROUTE), 0);
    }
  }

  render = () => {
    return <>
      <NavBar />
      <section className="pattern-jigsaw-zinc-500/5">
        <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-xl text-center">
            <h1 className="text-3xl font-extrabold sm:text-5xl">
              Organise ton équipe.
              <strong className="font-extrabold text-primary-400 sm:block"> Simplement. Efficacement. </strong>
            </h1>

            <p className="mt-4 sm:text-xl/relaxed">
              Enregistre tes sessions, partage les à tes joueurs. Notifications par e-mails, gestion des remplaçants, désistements... Tout est là. 
            </p>

            <div className="mt-8 flex flex-wrap justify-center gap-4">
              <NavLink
                className="block w-full rounded bg-primary-500 px-12 py-3 text-sm font-medium text-white shadow hover:bg-primary-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto"
                to={SIGNUP_ROUTE}
              >
                Inscription
              </NavLink>

              <NavLink
                className="block w-full rounded px-12 py-3 text-sm dark:bg-zinc-900 bg-white font-medium text-primary-500 shadow hover:text-primary-700 focus:outline-none focus:ring active:text-primary-500 sm:w-auto"
                to={LOGIN_ROUTE}
              >
                J'ai déja un compte
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>;
  }
}

export default authctx.withCtx(withRouter(Home));
