export const getRouteWithReplacement = (route, params) => {
  if (!route) return "";

  let finalRoute = route;

  if (params) {
    Object.keys(params).forEach((param) => {
      finalRoute = finalRoute.replace(`:${param}`, params[param]);
    });
  }

  return finalRoute;
}
