import React from "react";
import authctx from "../contexts/authctx.js";
import {
  Button,
  Label,
  Select,
  Spinner,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';
import { enqueueSnackbar } from "notistack";
import { createSession } from "../services/api.js";
import { TbCoinEuro, TbLocation } from "react-icons/tb";
import PlacesAutocomplete from 'react-places-autocomplete';
import { SessionType } from "../services/enums.js";
import Datepicker from "tailwind-datepicker-react";
import { SESSION_DETAILS_ROUTE } from "../services/routes.js";
import withRouter from "../components/wrappers/withrouter.js";
import { getRouteWithReplacement } from "../services/router.js";
import dayjs from "dayjs";

const datePickerOptions = {
  title: "Date",
  autoHide: true,
  todayBtn: true,
  clearBtn: true,
  clearBtnText: "Effacer",
  language: "fr",
  minDate: (new Date()).setDate((new Date()).getDate() - 1),
  theme: {
    background: "bg-white",
    disabledText: "bg-gray-100 opacity-50",
  },
  icons: {
    // () => ReactElement | JSX.Element
    prev: () => <span className="text-sm">Précédent</span>,
    next: () => <span className="text-sm">Suivant</span>,
  },
  datepickerClassNames: "top-12",
  defaultDate: new Date(),
  disabledDates: [],
  weekDays: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

class CreateSession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      address: "", 
      placeId: null,
      nbPlayers: 10,
      sessionType: SessionType.INTERIOR,
      userIsInGame: true,
      pricePerPerson: 10.0,
      date: new Date(),
      duration: 1,
      hour: 20,
      minutes: 0,
      loadingCreation: false,
    };
  }

  handleCreateSession = async () => {
    this.setState({ loadingCreation: true });

    try {
      let date = dayjs(this.state.date);

      date = date.set("hour", this.state.hour);
      date = date.set("minute", this.state.minutes);
      date = date.set("second", 0);
      date = date.utc();

      const session = await createSession(
        this.state.placeId,
        date.toISOString(),
        this.state.duration,
        this.state.nbPlayers,
        this.state.sessionType,
        this.state.userIsInGame,
        parseFloat(this.state.pricePerPerson),
      );

      enqueueSnackbar("La session a été créée avec succès", {variant: "success"});

      this.props.router.navigate(
        getRouteWithReplacement(SESSION_DETAILS_ROUTE, { id: session.id})
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la création de la session",
        {variant: "error"},
      )
    } finally {
      this.setState({ loadingCreation: false });
    }
  };

  handleDateChange = (selectedDate) => this.setState({date: selectedDate});
  handleChange = (ev) => this.setState({[ev.target.name]: ev.target.value});
  handleSwitch = (ev) => this.setState({[ev.target.name]: ev.target.checked});
  handleChangeFloat = (ev) => this.setState({[ev.target.name]: parseFloat(ev.target.checked)});

  handleToggleDatePicker = () => {
    this.setState(prevState => ({ showDatePicker: !prevState.showDatePicker }));
  }

  handleChangeAddress = address => {
    if (this.state.placeId) {
      this.setState({ placeId: null });
    }
    this.setState({ address });
  };

  handleSelectPlace = (fullAddress, placeId) => {
    if (placeId) {
      this.setState({ placeId, address: fullAddress });
    }
  };

  _renderPlaceInput = () => {
    return <>
      <Label htmlFor="location" value="Lieu / Endroit" />

      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChangeAddress}
        onSelect={this.handleSelectPlace}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <React.Fragment key={getInputProps().key}>
            <TextInput
              id="location"
              icon={TbLocation}
              placeholder="Foot Indoor, Mon Club 2.0..."
              color={this.state.placeId
                ? "success"
                : (this.state.address && !this.state.placeId 
                    && suggestions.length === 0 && !loading
                  ? "failure"
                  : "grey")
              }
              required
              helperText={this.state.address && !this.state.placeId 
                && suggestions.length === 0 && !loading
                ? "Merci de sélectionner un lieu dans la liste"
                : undefined}
              {...getInputProps()}
            />

            <div className="space-x-1">
              {loading && <div className="text-center">
                <Spinner />
              </div>}
              {suggestions.map(suggestion => {
                let className = "border-b-2 border-gray-200 dark:border-gray-700 p-2 w-full bg-white dark:bg-zinc-900"
                className += suggestion.active
                  ? "bg-zinc-100 dark:bg-zinc-800"
                  : "";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: 'pointer', backgroundColor: '#fafafa'}
                  : { cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </PlacesAutocomplete>
    </>;
  }

  handleSelectNbPlayers10 = () => this.setState({ nbPlayers: 10 });
  handleSelectNbPlayers12 = () => this.setState({ nbPlayers: 12 });
  handleSelectNbPlayers14 = () => this.setState({ nbPlayers: 14 });

  _renderNbPlayersInput = () => {
    return <>
        <Label
          htmlFor="nbPlayers"
          value="Nombre de joueurs"
        />

      <Button.Group id="nbPlayers">
        <Button
          color={this.state.nbPlayers === 10 ? "info" : "gray"}
          onClick={this.handleSelectNbPlayers10}
          className="w-1/3"
        >
          10
        </Button>
        <Button
          color={this.state.nbPlayers === 12 ? "info" : "gray"}
          onClick={this.handleSelectNbPlayers12}
          className="w-1/3"
        >
          12
        </Button>
        <Button 
          color={this.state.nbPlayers === 14 ? "info" : "gray"}
          onClick={this.handleSelectNbPlayers14}
          className="w-1/3"
        >
          14
        </Button>
      </Button.Group>
    </>;
  };

  handleSelectDuration30 = () => this.setState({ duration: 0.5 });
  handleSelectDuration1 = () => this.setState({ duration: 1 });
  handleSelectDuration2 = () => this.setState({ duration: 2 });

  _renderDurationInput = () => {
    return <>
      <Label
        htmlFor="nbPlayers"
        value="Durée"
      />

      <Button.Group id="nbPlayers">
        <Button
          color={this.state.duration === 0.5 ? "info" : "gray"}
          onClick={this.handleSelectDuration30}
          className="w-1/3"
        >
          30min
        </Button>
        <Button
          color={this.state.duration === 1 ? "info" : "gray"}
          onClick={this.handleSelectDuration1}
          className="w-1/3"
        >
          1h
        </Button>
        <Button
          color={this.state.duration === 2 ? "info" : "gray"}
          onClick={this.handleSelectDuration2}
          className="w-1/3"
        >
          2h
        </Button>
      </Button.Group>
    </>;
  };

  _renderSessionTypeInput = () => {
    return <>
      <Label htmlFor="sessiontype" value="Type de terrain" />

      <Select 
        id="sessiontype"
        name="sessionType"
        value={this.state.sessionType}
        onChange={this.handleChange}
        required
      >
        <option value={SessionType.INTERIOR}>{SessionType.INTERIOR}</option>
        <option value={SessionType.EXTERIOR}>{SessionType.EXTERIOR}</option>
      </Select>
    </>
  }

  _renderUserIsInGameSwitch = () => {
    return <ToggleSwitch
      name="userIsInGame"
      checked={this.state.userIsInGame} 
      label="S'inscrire soi même dans la session" 
      onChange={this.handleSwitch} 
    />;
  }

  _renderPricePerPersonInput = () => {
    return <>
      <Label htmlFor="pricePerPerson" value="Prix par personne" />
      <TextInput 
        id="pricePerPerson"
        name="pricePerPerson"
        icon={TbCoinEuro}
        type="number"
        value={this.state.pricePerPerson}
        onChange={this.handleChange}
        required
      />
    </>
  }

  _renderForm = () => {
    return <>
      {this._renderPlaceInput()}
      {this._renderNbPlayersInput()}
      {this._renderHourMinutesInput()}
      {this._renderDurationInput()}
      {this._renderSessionTypeInput()}
      {this._renderPricePerPersonInput()}

        <div className="mb-2 block">
          <Label value="Paramètres" />
        </div>
        {this._renderUserIsInGameSwitch()}
    </>
  };

  handleHourChange = (ev) => this.setState({hour: ev});

  _renderHourMinutesInput = () => {
    return <>
      <Label value="Date et heure" />

      <Datepicker
        options={datePickerOptions}
        onChange={this.handleDateChange}
        show={this.state.showDatePicker}
        setShow={this.handleToggleDatePicker}
        value={this.state.date}
      />

      <div className="flex gap-5">
        <div className="flex-1">
          <Select
            id="hour"
            name="hour"
            value={this.state.hour}
            onChange={this.handleChange}
            required
          >
            <option value={17}>17h</option>
            <option value={18}>18h</option>
            <option value={19}>19h</option>
            <option value={20}>20h</option>
            <option value={21}>21h</option>
            <option value={22}>22h</option>
            <option value={23}>23h</option>
          </Select>
        </div>
        <div className="flex-1">
          <Select
            id="minutes"
            name="minutes"
            value={this.state.minutes}
            onChange={this.handleChange}
            required
          >
            <option value={0}>00</option>
            <option value={30}>30</option>
          </Select>
        </div>
      </div>
      
    </>;
  }


  render() {
    if (this.state.loadingCreation) {
      return <div className="text-center h-full">
        <Spinner />
      </div>
    }

    return <>
      <h6 className="text-3xl text-center pt-3 pb-5">Créer une session</h6>
      {this._renderForm()}

      <Button
        disabled={!this.state.placeId
          || this.state.pricePerPerson === ""
          || this.state.loading}
        onClick={this.handleCreateSession}
        className="w-full"
      >
        Créer la session
      </Button>
    </>;
  }
}

export default withRouter(authctx.withCtx(CreateSession));
