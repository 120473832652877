import React from "react";
import FifaCard from "../components/FifaCard";
import authctx from "../contexts/authctx.js";
import { ListGroup, Spinner } from 'flowbite-react';
import { enqueueSnackbar } from "notistack";
import { getUser } from "../services/api.js";
import { DASHBOARD_ROUTE } from "../services/routes.js";
import withRouter from "../components/wrappers/withrouter.js";
import { formatDate } from "../services/dates.js";

class UserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null};
  }

  componentDidMount = () => {
    this.handleGetData();
  }

  handleGetData = async () => {
    const id = this.props.router.params.id;

    if (!id) {
      enqueueSnackbar(
        "Erreur avec la récupération de l'utilisateur",
        {variant: "error"},
      );

      return this.props.router.navigate(DASHBOARD_ROUTE);
    }

    try {
      const user = await getUser(id);

      console.log(user)

      this.setState({data: user});
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des données",
        { variant: "error" },
      )
    }
  }

  render() {
    if (!this.state.data) return <Spinner />;

    const user = this.state.data.user;
    const stats = this.state.data.stats;
    const lastActivity = formatDate(this.state.data.lastActivity);

    return <>
      <div className="flex justify-center">
        <FifaCard scaleValue={0.4} user={user} />
      </div>

      <h6 className="text-3xl text-center pt-3">
        {user.firstName} {user.lastName}
      </h6>

       <h5 className="text-1xl text-center pb-5">
        {user.age} ans {user.nickname ? `(${ user.nickname })` : null}
      </h5>

      {user.nickname ? <span className="text-1xl text-center pb-5">
        Dernière activité sur le site: {lastActivity}
      </span> : null}

      <hr className="pt-3 pb-3" />

      <div className="flex justify-center">
        <ListGroup className="w-full">
          <ListGroup.Item>
            {stats.sessions.played} sessions jouées
          </ListGroup.Item>
          <ListGroup.Item>
            {stats.sessions.refused} sessions refusées
          </ListGroup.Item>
          <ListGroup.Item>
            {stats.sessions.substitute} sessions en remplaçant
          </ListGroup.Item>
          <ListGroup.Item>
            {stats.sessions.organised} sessions organisées
          </ListGroup.Item>
        </ListGroup>
      </div>

      
    </>;
  }
}

export default authctx.withCtx(withRouter(UserDetails));
