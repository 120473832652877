import React from "react";

const PageContent = ({children}) => {
  return (
    <div className="flex flex-col items-center w-screen gap-2 center-content">
      <div className="sm:w-full sm:p-16 md:w-1/2 lg:w-1/2 p-5 space-y-5 pb-10 flex flex-col w-full">
        {children}
      </div>
    </div>
  );
};

export default PageContent;
