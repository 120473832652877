import React from "react";
import fifacard from "../images/fifacard.png";
// import flagFrance from "../images/flag-france.svg";
// import logoOM from "../images/logo-om.svg";
import { useRef, useEffect } from "react";
import unknownAvatar from "../images/unknown.svg";

const FifaCard = ({ scaleValue = 0.5, user }) => {
  // console.log("user", user);
  const fifaCardRef = useRef(null);
  const fifaCardContainerRef = useRef(null);

  useEffect(() => {
    if (fifaCardRef.current && fifaCardContainerRef.current) {
      const fifaCard = fifaCardRef.current;
      const fifaCardContainer = fifaCardContainerRef.current;

      // Obtenez les styles calculés
      const computedStyles = window.getComputedStyle(fifaCard);

      // Obtenez la largeur et la hauteur calculées
      const width = parseFloat(computedStyles.getPropertyValue("width"));
      const height = parseFloat(computedStyles.getPropertyValue("height"));

      // Appliquez la nouvelle largeur en tenant compte de l'échelle
      fifaCardContainer.style.width = `${width * scaleValue}px`;
      fifaCardContainer.style.height = `${height * scaleValue}px`;
    }
  }, [scaleValue]);

  return (
    <div ref={fifaCardContainerRef}>
      <div
        ref={fifaCardRef}
        style={{ transform: `scale(${scaleValue}, ${scaleValue})` }}
        className={`fifacard relative origin-top-left w-[497px] h-[792px] text-white`}
      >
        <span className="absolute z-10 text-primary-300 bottom-[310px] left-1/2 -translate-x-1/2 text-4xl font-semibold ">
          {user.lastName}
        </span>

        {/* <div className="absolute z-10 top-[260px] left-[83px]">
          <img src={flagFrance} className="w-[60px]" alt="" />
        </div> */}

        {/* <div className="absolute z-10 top-[330px] left-[83px]">
          <img src={logoOM} className="w-[60px]" alt="" />
        </div> */}

        <img src={fifacard} alt="" className="absolute" />
        <div className="absolute bottom-[373px] left-[60%] -translate-x-1/2">
          <img
            src={user.avatar ?? unknownAvatar}
            className="origin-bottom scale-150 max-h-[190px]"
            alt=""
          />
        </div>

        <div className="relative  w-fit top-[70px] left-[70px] flex flex-col">
          <span className="text-[80px] leading-none">{user.averageNote}</span>
          <span className="absolute top-[80px]  left-1/2 -translate-x-1/2 text-3xl border-b w-fit">
            {user.preferedPosition}
          </span>
        </div>

        <div className="absolute  left-[100px] bottom-[118px] text-3xl">
          <div className="w-[120px]">
            <div className="flex flex-col h-[150px] justify-between">
              <div className="flex justify-between">
                <div className="text-primary-100 font-semibold">
                  {user.noteSpeed}
                </div>
                <div className="font-medium text-primary-200">PAC</div>
              </div>
              <div className="flex justify-between">
                <div className="text-primary-100 font-semibold">
                  {user.noteShoot}
                </div>
                <div className="font-medium text-primary-200">SHO</div>
              </div>
              <div className="flex justify-between">
                <div className="text-primary-100 font-semibold">
                  {user.notePass}
                </div>
                <div className="font-medium text-primary-200">PAS</div>
              </div>
            </div>
          </div>

          <div className="absolute left-[175px] bottom-[1px] text-3xl">
            <div className="w-[120px]">
              <div className="flex flex-col h-[150px] justify-between">
                <div className="flex justify-between">
                  <div className="text-primary-100 font-semibold">
                    {user.noteDribble}
                  </div>
                  <div className="font-medium text-primary-200">DRI</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-primary-100 font-semibold">
                    {user.noteDefense}
                  </div>
                  <div className="font-medium text-primary-200">DEF</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-primary-100 font-semibold">
                    {user.notePhysical}
                  </div>
                  <div className="font-medium text-primary-200">PHY</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifaCard;
