import React from "react";
import NavBar from "../components/NavBar";
import { NavLink } from "react-router-dom";
import { DASHBOARD_ROUTE, FORGOT_PASSWORD_ROUTE, SIGNUP_ROUTE } from "../services/routes.js";
import { login } from "../services/api.js";
import withRouter from "../components/wrappers/withrouter.js";
import authctx from "../contexts/authctx.js";
import { enqueueSnackbar } from "notistack";
import { Label, Spinner, TextInput } from "flowbite-react";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount = () => {
    if (this.props.authCtx.profile) {
      return setTimeout(() =>this.props.router.navigate(DASHBOARD_ROUTE), 0);
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({loading: true});

    try {
      await login(this.state.email, this.state.password);

      await this.props.authCtx.getProfile();

      this.props.router.navigate(DASHBOARD_ROUTE);
      
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        "E-mail ou mot de passe incorrect",
        { variant: "error" },
      );

      this.setState({loading: false});
    }
  }

  _renderForm = () => {
    return <>
      <h6 className="text-3xl text-center pt-3 pb-5">
        Connexion
      </h6>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">

        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="E-mail" />
          </div>
          <TextInput
            onChange={this.handleInputChange}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            value={this.state.email}
            required
            disabled={this.state.loading}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Mot de passe" />
          </div>
          <TextInput
            onChange={this.handleInputChange}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            value={this.state.password}
            disabled={this.state.loading}
          />
          <div className="text-sm mt-2">
            {this.state.loading ? <span className="font-semibold text-xs text-primary-600 hover:text-primary-500">
              Mot de passe oublié ?
            </span> : <NavLink
              to={FORGOT_PASSWORD_ROUTE}
              className="font-semibold text-xs text-primary-600 hover:text-primary-500"
            >
              Mot de passe oublié ?
            </NavLink>}
          </div>
        </div>

          <div>
            {this.state.loading ? <div className="text-center mt-3 mb-3">
              <Spinner />
            </div> : <button
              type="submit"
              onClick={this.handleSubmit}
              disabled={this.state.email === "" || this.state.password === ""}
              className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Se connecter
            </button>}

            <hr className="mt-5 mb-5" />

            <NavLink
              className="text-xs flex mt-4 text-primary-600 w-full justify-center"
              to={SIGNUP_ROUTE}
              disabled
            >
              Pas de compte ? S'inscrire
            </NavLink>
          </div>
        </form>
      </div>
    </>
  };

  render = () => {
    return <>
      <NavBar />
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {this._renderForm()}
      </div>
    </>
  };
}

export default authctx.withCtx(withRouter(Login));
