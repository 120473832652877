import stateContext from "@cley_faye/react-utils/lib/context/state.js";
import {
  getProfile,
  logout,
} from "../services/api.js";

export default stateContext(
  "auth",
  {
    profile: null,
  },
  {
    getProfile: async (ctx) => {
      const profileResp = await getProfile();

      ctx.setContext({ profile: profileResp.data.user });

      return profileResp.data.user;
    },
    logout: async (ctx) => {
      await logout();
      return ctx.setContext({ profile: null });
    },
  },
);