import React from "react";
import NavBar from "../components/NavBar";
import { NavLink } from "react-router-dom";
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "../services/routes.js";
import { changeForgottenPassword, forgotPassword, login } from "../services/api.js";
import withRouter from "../components/wrappers/withrouter.js";
import authctx from "../contexts/authctx.js";
import { enqueueSnackbar } from "notistack";
import * as EmailValidator from "email-validator";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {email: "", token: null};
  }

  componentDidMount = () => {
    if (this.props.authCtx.profile) {
      return this.props.router.navigate(DASHBOARD_ROUTE);
    }

    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      this.setState({token: token});
    }
  }

  handleInputChange = (e) => this.setState({[e.target.name]: e.target.value});

  handleSubmitChangePassword = async (e) => {
    e.preventDefault();

    try {
      await changeForgottenPassword(this.state.newPassword, this.state.token);

      const email = JSON.parse(atob(this.state.token.split(".")[1])).email;

      await login(email, this.state.newPassword);

      await this.props.authCtx.getProfile();

      this.props.router.navigate(DASHBOARD_ROUTE)

      enqueueSnackbar(
        "Votre mot de passe a été modifié avec succès",
        { variant: "success" },
      );

      this.props.router.navigate(LOGIN_ROUTE);
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        "Une erreur est survenue, veuillez réessayer",
        { variant: "error" },
      );
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await forgotPassword(this.state.email);

      enqueueSnackbar(
        "Un e-mail de réinitialisation de mot de passe vous a été envoyé",
        { variant: "success" },
      );
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        "Une erreur est survenue, veuillez réessayer",
        { variant: "error" },
      );
    }
  }

  _renderResetPasswordForm = () => {
    return <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="space-y-6">
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nouveau mot de passe
          </label>
          <div className="mt-2">
            <input
              onChange={this.handleInputChange}
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              required
              className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Confirmation du nouveau mot de passe
          </label>
          <div className="mt-2">
            <input
              onChange={this.handleInputChange}
              id="newPassword"
              name="newPassword"
              type="password"
              autoComplete="password"
              required
              className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

          <button
            type="submit"
            onClick={this.handleSubmitChangePassword}
            disabled={this.state.password === "" || this.state.newPassword === "" || this.state.password !== this.state.newPassword}
            className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Changer mon mot de passe
          </button>
      </div>
    </div>;
  };

  _renderForm = () => {
    return <>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Adresse e-mail
              </label>
              <div className="mt-2">
                <input
                  onChange={this.handleInputChange}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={this.handleSubmit}
                disabled={this.state.email === "" || !EmailValidator.validate(this.state.email)}
                className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Réinitialiser le mot de passe
              </button>
              <NavLink
                className="text-xs flex mt-4 text-primary-600 w-full justify-center"
                to={LOGIN_ROUTE}
              >
                J'ai retrouvé mon mot de passe
              </NavLink>
            </div>
            </>;
  };

  _renderByType = () => {
    if (this.state.token) {
      return this._renderResetPasswordForm();
    }

    return this._renderForm();
  }

  render = () => {
    return <>
      <NavBar />
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Mot de passe oublié ?
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
              {this._renderByType()}
            </form>
          </div>
        </div>
      </>
  };
}

export default authctx.withCtx(withRouter(ForgotPassword));
