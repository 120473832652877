import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "../../services/routes.js";
import NavBar from "../NavBar.js";
import authctx from "../../contexts/authctx.js";
import PageContent from "./PageContent.js";

const BasePage = ({authCtx}) => {
  const locationObject = useLocation();

  if (!authCtx.profile) {
    return <Navigate
      to={LOGIN_ROUTE}
      state={{from: locationObject}}
      replace
    />;
  }

  return <>
    <NavBar />

    <PageContent>
      <Outlet />
    </PageContent>
  </>
}

export default authctx.withCtx(BasePage);