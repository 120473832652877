import React from "react";
import Datepicker from "tailwind-datepicker-react";
import NavBar from "../components/NavBar";
import { NavLink } from "react-router-dom";
import { DASHBOARD_ROUTE } from "../services/routes.js";
import { login, signup } from "../services/api.js";
import authctx from "../contexts/authctx.js";
import withRouter from "../components/wrappers/withrouter.js";
import { enqueueSnackbar } from "notistack";

const options = {
  title: "Date de naissance",
  autoHide: true,
  todayBtn: false,
  clearBtn: true,
  clearBtnText: "Effacer",
  language: "fr",
  maxDate: new Date(),
  minDate: new Date("1950-01-01"),
  theme: {
    background: "bg-white",
    todayBtn: "",

    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "bg-gray-100 opacity-50",
    input: "",
    inputIcon: "",
    selected: "",
  },
  icons: {
    // () => ReactElement | JSX.Element
    prev: () => <span className="text-sm">Précédent</span>,
    next: () => <span className="text-sm">Suivant</span>,
  },
  datepickerClassNames: "top-12",
  defaultDate: new Date("1998-01-01"),
  disabledDates: [],
  weekDays: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Date de naissance",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      birthdate: "",
      confirmPassword: "",
    };
  }

  componentDidMount = () => {
    if (this.props.authCtx.profile) {
      return setTimeout(() =>this.props.router.navigate(DASHBOARD_ROUTE), 100);
    }
  }

  handleToggleDatePicker = () => {
    this.setState(prevState => ({showDatePicker: !prevState.showDatePicker}));
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDateChange = (selectedDate) => this.setState({birthdate: selectedDate});


  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signup(
        this.state.email,
        this.state.password,
        this.state.firstName,
        this.state.lastName,
        this.state.birthdate
      );

      await login(this.state.email, this.state.password);

      await this.props.authCtx.getProfile();

      this.props.router.navigate(DASHBOARD_ROUTE);

    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        "Une erreur s'est produite lors de l'inscription",
        { variant: "error" },
      );
    }
  }

  render() {
    return <>
      <NavBar />
      <div className="flex flex-1 flex-col justify-center px-6 py-2 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Inscription
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={this.handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Adresse e-mail
              </label>
              <div className="mt-2 mb-2">
                <input
                  onChange={this.handleInputChange}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>

              <div className="flex w-full gap-4 mt-4s">
                <div className="w-full">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Prénom
                  </label>
                  <div className="mt-2 mb-2">
                  <input
                      onChange={this.handleInputChange}
                      id="firstName"
                      name="firstName"
                      type="text"
                      autoComplete="firstName"
                      required
                      className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              <div className="w-full">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nom{" "}
                  </label>
                  <div className="mt-2 mb-2">
                  <input
                      onChange={this.handleInputChange}
                      id="lastName"
                      name="lastName"
                      type="text"
                      autoComplete="lastName"
                      required
                      className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="date"
                  className="block mb-2 text-sm font-medium leading-6 text-gray-900"
                >
                  Date de naissance
                </label>
                <Datepicker
                  options={options}
                  onChange={this.handleDateChange}
                  show={this.state.showDatePicker}
                  setShow={this.handleToggleDatePicker}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mot de passe
                </label>
              </div>

              <div>
                <div className="mt-2 mb-2">
                  <input
                  id="password"
                  onChange={this.handleInputChange}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirmation mot de passe
                </label>
                <div className="mt-2 mb-2">
                <input
                  onChange={this.handleInputChange}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                disabled={this.state.email === ""
                || this.state.firstName === ""
                || this.state.lastName === ""
                || this.state.birthdate === ""
                || this.state.password === "" 
                || this.state.password !== this.state.confirmPassword}
                className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                S'inscrire
              </button>
              <NavLink
                className="text-xs flex mt-4 text-primary-600 w-full justify-center"
                to="/login"
              >
                Déjà un compte ? Connectez-vous
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </>;
  }
}

export default withRouter(authctx.withCtx(SignUp));
