import React from "react";
import PageContent from "../components/layout/PageContent.js";
import NavBar from "../components/NavBar.js";
import withRouter from "../components/wrappers/withrouter.js";
import { enqueueSnackbar } from "notistack";
import { quickResponse } from "../services/api.js";
import { Alert, Spinner } from "flowbite-react";
import { HOME_ROUTE } from "../services/routes.js";

class QuickAcceptSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      accepted: null,
    };
  }

  componentDidMount = () => {
    const token = new URLSearchParams(window.location.search).get("jwt");

    this.handleResponse(token);
    if (!token) {
      console.error("No token found");
      enqueueSnackbar("Lien invalide", {variant: "error"});

      return setTimeout(() => this.props.router.navigate(HOME_ROUTE), 100);
    }
  }

  handleResponse = async (token) => {
    try {
      const resp = await quickResponse(token);

      if (resp.status !== "ok") {
        return this.setState({error: resp.message});
      }

      this.setState({accepted: resp.accepted});
    } catch (err) {
      this.setState({error: err.message});
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la réponse à la session",
        {variant: "error"}
      );
    } finally {
      this.setState({loading: false});
    }
  }

  _renderError = () => {
    return <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-red-600">
          Une erreur est survenue
        </h2>
        <div className="mt-4 text-center">
          <p className="text-sm leading-5 text-gray-600">
            {this.state.error}
          </p>
        </div>
      </div>
    </div>;
  }

  _renderContent = () => {
    if (this.state.loading) {
      return <div className="text-center h-full">
      <Spinner />
    </div>;
    }

    if (this.state.error) {
      return this._renderError();
    }

    if (this.state.accepted === true) {
      if (this.state.substitute) {
        return <Alert color="warning">
          Vous avez accepté la session, mais elle est pleine et vous avez été placé en liste d'attente, en tant que remplaçant.
        </Alert>
      }

      return <Alert color="success">
        Vous avez accepté la session, vous avez reçu les détails par e-mail.
      </Alert>;
    }

    if (this.state.accepted === false) {
      return <Alert color="failure">
        Vous avez refusé la session.
      </Alert>;
    }

    return "...";
  }

  render() {
    return <>
      <NavBar />
      <PageContent>
          {this._renderContent()}
      </PageContent>
    </>;
  }
}

export default withRouter(QuickAcceptSession);