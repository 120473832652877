import React from "react";
import { getLastSessions } from "../services/api.js";
import { Button, Spinner, Timeline } from "flowbite-react";
import FutSession from "../components/FutSession.js";
import { enqueueSnackbar } from "notistack";
import { TbArrowLeft, TbArrowRight } from "react-icons/tb";

const TABS_AVAILABILITY = {
  ONLY_INSCRIPTIBLES: {onlyInscriptibles: true},
  ONLY_NOT_INSCRIPTIBLES: {onlyNotInscriptibles: true},
}

class SessionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabAvailability: TABS_AVAILABILITY.ONLY_INSCRIPTIBLES,
      sessions: null,
      page: 1,
      limit: 10,
      total: 0,
    };
  };

  componentDidMount() {
    this._fetchSessions();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState.tabAvailability !== this.state.tabAvailability
      || prevState.page !== this.state.page
    ) {
      this._fetchSessions();
    }
  }

  _fetchSessions = async () => {
    this.setState({loading: true});
    try {
      const apiResp = await getLastSessions(
        this.state.page,
        this.state.limit,
        { ...this.state.tabAvailability },
      );
      this.setState({
        sessions: apiResp.sessions,
        total: apiResp.total,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des données",
        { variant: "error" },
      );
    } finally {
      this.setState({loading: false});
    }
  }

  _renderPagination = () => {
    return <div className="flex space-x-2">
      <Button
        onClick={this._previousPage}
        disabled={this.state.page === 1}
        className="flex-1"
      >
        <TbArrowLeft />
      </Button>
      <Button
        onClick={this._nextPage}
        disabled={this.state.page * this.state.limit >= this.state.total}
        className="flex-1"
      >
        <TbArrowRight />
      </Button>
    </div>;
  }

  handleSetTabAvailabilityAvailable = () => this.setState({
    tabAvailability: TABS_AVAILABILITY.ONLY_INSCRIPTIBLES,
  });
  handleSetTabAvailabilityNotAvailable = () => this.setState({
    tabAvailability: TABS_AVAILABILITY.ONLY_NOT_INSCRIPTIBLES,
  });

  _renderTabs = () => {
    return <div className="text-center">
      <Button.Group>
        <Button
          color={this.state.tabAvailability === TABS_AVAILABILITY.ONLY_INSCRIPTIBLES 
            ? undefined 
            : "gray"}
          onClick={this.handleSetTabAvailabilityAvailable}
        >
          Disponibles
        </Button>
        <Button
          color={this.state.tabAvailability === TABS_AVAILABILITY.ONLY_NOT_INSCRIPTIBLES 
            ? undefined 
            : "gray"}
          onClick={this.handleSetTabAvailabilityNotAvailable}
        >
          Déja passées
        </Button>
      </Button.Group>
    </div>;
  }

  render() {
    if (!this.state.sessions) return <div className="text-center">
      <Spinner />
    </div>;

    return <>
      <h6 className="text-3xl text-center pt-3 pb-5">
        Liste des sessions
      </h6>
      <hr />
      {this._renderTabs()}

      <Timeline>
        {this.state.sessions.map((session) => <FutSession 
          key={session.id}
          session={session}
          timeline
        />)}
      </Timeline>
      
      {this._renderPagination()}
    </>;
  }
}

export default SessionList;