import React from "react";
import {
  Card,
  Progress,
  Badge,
  Button,
  Alert,
  Timeline,
} from 'flowbite-react';
import { TbHomeUp, TbTree, TbArrowRight, TbMap, TbCalendar, TbPhone, TbCrown } from 'react-icons/tb';
import { Link, NavLink } from "react-router-dom";
import { getRouteWithReplacement } from "../services/router.js";
import { SESSION_DETAILS_ROUTE } from "../services/routes.js";
import { capitalize, formatDate } from "../services/dates.js";
import { getStatusBarColorFromPlayers, hasPlayerRefusedGame, isGamePassed, isPlayerRegisteredToGame, isPlayerSubstituteToGame } from "../services/futsession.js";
import { SessionType } from "../services/enums.js";
import authctx from "../contexts/authctx.js";

class FutSession extends React.Component {
  _renderDetailsButtons = () => {
    if (this.props.hideDetails) return null;
    const session = this.props.session;
    return <>
      <Link to={session.place.json.url} target="_blank">
        <Button className="w-full">
          Voir sur Google Maps
          <TbMap className="ml-3" />
        </Button>
      </Link>
      <Link to={session.addToGoogleAgendaUrl} target="_blank">
        <Button className="w-full">
          Ajouter à Google Agenda
          <TbCalendar className="ml-3" />
        </Button>
      </Link>
    </>;
  }

  _renderActualUserAlert = () => {
    if (this.props.hideAlert) return null;

    const AlertComp = this.props.timeline ? Badge : Alert;

    if (isGamePassed(this.props.session)) {
      if (isPlayerRegisteredToGame(this.props.authCtx.profile.id, this.props.session)) {
        return <AlertComp
        color="success"
        rounded={this.props.timeline ? undefined : true}
        className={this.props.timeline ? "inline ml-2" : ""}
      >
          {this.props.timeline
            ? "Terminé, participé"
            : "La partie est passée, merci d'avoir participé!"}
        </AlertComp>;
      }

      return <AlertComp
        color="failure"
        rounded={this.props.timeline ? undefined : true}
        className={this.props.timeline ? "inline ml-2" : ""}
      >
        {this.props.timeline
          ? "Terminée"
          : "La partie est passée"}
      </AlertComp>;
    }

    if (hasPlayerRefusedGame(this.props.authCtx.profile.id, this.props.session)) {
      return <AlertComp
        color="failure"
        
        rounded={this.props.timeline ? undefined : true}
        className={this.props.timeline ? "inline ml-2" : ""}
      >
        {this.props.timeline
          ? "Vous avez refusé"
          : "Vous avez refusé de participer à cette partie"}
      </AlertComp>;
    }
    if (isPlayerSubstituteToGame(this.props.authCtx.profile.id, this.props.session)) {
      return <AlertComp
        color="warning"
        
        rounded={this.props.timeline ? undefined : true}
        className={this.props.timeline ? "inline ml-2" : ""}
      >
        {this.props.timeline
          ? "Vous êtes remplaçant"
          : "Vous êtes inscrit comme remplaçant potentiel à cette partie"}
      </AlertComp>;
    }
    if (isPlayerRegisteredToGame(this.props.authCtx.profile.id, this.props.session)) {
      return <AlertComp
        color="success"
        
        rounded={this.props.timeline ? undefined : true}
        className={this.props.timeline ? "inline ml-2" : ""}
      >
        {this.props.timeline
          ? "Vous êtes inscrit"
          : "Vous êtes inscrit à cette partie"}
      </AlertComp>;
    }
  }

  _renderTimelineItem = () => {
    const session = this.props.session;
    const dateTime = formatDate(session.date);

    return <NavLink to={getRouteWithReplacement(SESSION_DETAILS_ROUTE, { id: session.id })}>
      <Timeline.Item className="border-zinc-300 border rounded-lg p-4">
        <Timeline.Point />
        <Timeline.Content>
          <Timeline.Time>
            {capitalize(dateTime)}
            {this._renderActualUserAlert()}
          </Timeline.Time>
          <Timeline.Title>{session.place.json.name}</Timeline.Title>
          <Timeline.Body  className="border-zinc-950 border-1">
            {session.place.json.formatted_address}
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
    </NavLink>
  }

  render() {
    const session = this.props.session;

    if (!session) return "Error: No session prop provided";
    if (this.props.timeline) return this._renderTimelineItem();

    const dateTime = formatDate(session.date);
    const playersCount = session.validatedPlayers.length;
    const substitudesCount = session.substitutePlayers.length;
    const percentagePlayers = Math.floor((playersCount / session.maxPlayerCount) * 100);
    const phoneNumber = session.place.json.formatted_phone_number;

    return (
      <Card className="w-full">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {capitalize(dateTime)}
        </h5>

        <h5 className="text-1xl font-bold tracking-tight text-gray-900 dark:text-white">
          {session.place.json.name}
        </h5>

        <p className="font-normal text-gray-700 dark:text-gray-400">
          {session.place.json.formatted_address}
          &nbsp;-&nbsp;
          <Link to={`tel:${phoneNumber}`}>
            <TbPhone className="inline" size={16} /> {session.place.json.formatted_phone_number}
          </Link>
          &nbsp;-&nbsp;
          <small className="italic">{session.pricePerPerson} € / joueur</small> 
        </p>

        <div className="flex items-end gap-2">
          {session.type === SessionType.INTERIOR 
            ? <Badge color="green" icon={TbHomeUp}>Intérieur</Badge> 
            : <Badge color="blue" icon={TbTree}>Extérieur</Badge>}
          <Badge color="blue" icon={TbCrown}>
            Organisateur : {session.organiser.firstName} {session.organiser.lastName}
          </Badge>
        </div>

        <Progress
          progress={percentagePlayers}
          textLabel={<>
            {playersCount} / {session.maxPlayerCount} joueurs {substitudesCount > 0 ? <small>(+{substitudesCount} remplaçants)</small> : null}
          </>}
          size="sm"
          labelText
          textLabelPosition="outside"
          color={getStatusBarColorFromPlayers(percentagePlayers)}
        />

        {this.props.hideLink ? null : <NavLink 
          to={getRouteWithReplacement(SESSION_DETAILS_ROUTE, {id: this.props.session.id})}
        >
          <Button className="w-full">
            Voir les détails
            <TbArrowRight className="ml-3" />
          </Button>
        </NavLink>}

        {this._renderActualUserAlert()}
        {this._renderDetailsButtons()}
      </Card>
    );
  }
}

export default authctx.withCtx(FutSession);
